export const getCoinImageSource = (coin) => `https://cdn.swapin.com/coins/png/${coin}.png`;

export const getApproximateAmount = (sum, rate) => {
  if (!sum || !rate) {
    return 0;
  }

  return (Math.ceil((sum / rate) * 10_000) / 10_000).toFixed(4);
};

export const getTotalFees = (data) => {
  const { bank_fees_currency, fees_currency, network_fees_currency } = data || {};

  return ((bank_fees_currency + fees_currency + network_fees_currency) || 0).toFixed(2);
};

export const normalizeChainId = (chainId) => {
  if (typeof chainId === 'string') {
    return Number.parseInt(
      chainId,
      chainId.trim().slice(0, 2) === '0x' ? 16 : 10,
    );
  }

  return chainId;
};
