import { L as Ee, V as M, u as Pe, q as H, X as A, B as ye, Y as Re, U as j, S as F, J as ee, H as te, Z as ne, _ as re, $ as oe, a0 as K, x as ie, R as k, a1 as se, v as ce, a2 as V, a3 as ae, a4 as ue, a5 as de, a6 as fe, T as le, p as je, y as Oe, l as xe, P as q, g as L, C as Z, n as he, a7 as B } from "./plugin-DK3uqrH4.js";
const U = /* @__PURE__ */ new Ee(8192);
function qe(t, { enabled: n = !0, id: r }) {
  if (!n || !r)
    return t();
  if (U.get(r))
    return U.get(r);
  const a = t().finally(() => U.delete(r));
  return U.set(r, a), a;
}
async function Ae(t) {
  return new Promise((n) => setTimeout(n, t));
}
function ve(t, { delay: n = 100, retryCount: r = 2, shouldRetry: a = () => !0 } = {}) {
  return new Promise((s, u) => {
    const p = async ({ count: f = 0 } = {}) => {
      const o = async ({ error: e }) => {
        const i = typeof n == "function" ? n({ count: f, error: e }) : n;
        i && await Ae(i), p({ count: f + 1 });
      };
      try {
        const e = await t();
        s(e);
      } catch (e) {
        if (f < r && await a({ count: f, error: e }))
          return o({ error: e });
        u(e);
      }
    };
    p();
  });
}
function ke(t, n = {}) {
  return async (r, a = {}) => {
    const { dedupe: s = !1, methods: u, retryDelay: p = 150, retryCount: f = 3, uid: o } = {
      ...n,
      ...a
    }, { method: e } = r;
    if (u?.exclude?.includes(e))
      throw new M(new Error("method not supported"), {
        method: e
      });
    if (u?.include && !u.include.includes(e))
      throw new M(new Error("method not supported"), {
        method: e
      });
    const i = s ? Pe(`${o}.${H(r)}`) : void 0;
    return qe(() => ve(async () => {
      try {
        return await t(r);
      } catch (c) {
        const d = c;
        switch (d.code) {
          case fe.code:
            throw new fe(d);
          case de.code:
            throw new de(d);
          case ue.code:
            throw new ue(d, { method: r.method });
          case ae.code:
            throw new ae(d);
          case V.code:
            throw new V(d);
          case ce.code:
            throw new ce(d);
          case se.code:
            throw new se(d);
          case k.code:
            throw new k(d);
          case ie.code:
            throw new ie(d);
          case M.code:
            throw new M(d, {
              method: r.method
            });
          case K.code:
            throw new K(d);
          case oe.code:
            throw new oe(d);
          case j.code:
            throw new j(d);
          case re.code:
            throw new re(d);
          case ne.code:
            throw new ne(d);
          case te.code:
            throw new te(d);
          case ee.code:
            throw new ee(d);
          case F.code:
            throw new F(d);
          case 5e3:
            throw new j(d);
          default:
            throw c instanceof ye ? c : new Re(d);
        }
      }
    }, {
      delay: ({ count: c, error: d }) => {
        if (d && d instanceof A) {
          const v = d?.headers?.get("Retry-After");
          if (v?.match(/\d/))
            return Number.parseInt(v) * 1e3;
        }
        return ~~(1 << c) * p;
      },
      retryCount: f,
      shouldRetry: ({ error: c }) => Te(c)
    }), { enabled: s, id: i });
  };
}
function Te(t) {
  return "code" in t && typeof t.code == "number" ? t.code === -1 || t.code === K.code || t.code === V.code : t instanceof A && t.status ? t.status === 403 || t.status === 408 || t.status === 413 || t.status === 429 || t.status === 500 || t.status === 502 || t.status === 503 || t.status === 504 : !0;
}
function be(t, { errorInstance: n = new Error("timed out"), timeout: r, signal: a }) {
  return new Promise((s, u) => {
    (async () => {
      let p;
      try {
        const f = new AbortController();
        r > 0 && (p = setTimeout(() => {
          a ? f.abort() : u(n);
        }, r)), s(await t({ signal: f?.signal || null }));
      } catch (f) {
        f?.name === "AbortError" && u(n), u(f);
      } finally {
        clearTimeout(p);
      }
    })();
  });
}
function Me() {
  return {
    current: 0,
    take() {
      return this.current++;
    },
    reset() {
      this.current = 0;
    }
  };
}
const pe = /* @__PURE__ */ Me();
function Le(t, n = {}) {
  return {
    async request(r) {
      const { body: a, onRequest: s = n.onRequest, onResponse: u = n.onResponse, timeout: p = n.timeout ?? 1e4 } = r, f = {
        ...n.fetchOptions ?? {},
        ...r.fetchOptions ?? {}
      }, { headers: o, method: e, signal: i } = f;
      try {
        const c = await be(async ({ signal: v }) => {
          const m = {
            ...f,
            body: Array.isArray(a) ? H(a.map((b) => ({
              jsonrpc: "2.0",
              id: b.id ?? pe.take(),
              ...b
            }))) : H({
              jsonrpc: "2.0",
              id: a.id ?? pe.take(),
              ...a
            }),
            headers: {
              "Content-Type": "application/json",
              ...o
            },
            method: e || "POST",
            signal: i || (p > 0 ? v : null)
          }, w = new Request(t, m), h = await s?.(w, m) ?? { ...m, url: t };
          return await fetch(h.url ?? t, h);
        }, {
          errorInstance: new le({ body: a, url: t }),
          timeout: p,
          signal: !0
        });
        u && await u(c);
        let d;
        if (c.headers.get("Content-Type")?.startsWith("application/json"))
          d = await c.json();
        else {
          d = await c.text();
          try {
            d = JSON.parse(d || "{}");
          } catch (v) {
            if (c.ok)
              throw v;
            d = { error: d };
          }
        }
        if (!c.ok)
          throw new A({
            body: a,
            details: H(d.error) || c.statusText,
            headers: c.headers,
            status: c.status,
            url: t
          });
        return d;
      } catch (c) {
        throw c instanceof A || c instanceof le ? c : new A({
          body: a,
          cause: c,
          url: t
        });
      }
    }
  };
}
const G = 256;
let $ = G, N;
function Ce(t = 11) {
  if (!N || $ + t > G * 2) {
    N = "", $ = 0;
    for (let n = 0; n < G; n++)
      N += (256 + Math.random() * 256 | 0).toString(16).substring(1);
  }
  return N.substring($, $++ + t);
}
function Ue(t) {
  const { batch: n, cacheTime: r = t.pollingInterval ?? 4e3, ccipRead: a, key: s = "base", name: u = "Base Client", pollingInterval: p = 4e3, type: f = "base" } = t, o = t.chain, e = t.account ? je(t.account) : void 0, { config: i, request: c, value: d } = t.transport({
    chain: o,
    pollingInterval: p
  }), v = { ...i, ...d }, m = {
    account: e,
    batch: n,
    cacheTime: r,
    ccipRead: a,
    chain: o,
    key: s,
    name: u,
    pollingInterval: p,
    request: c,
    transport: v,
    type: f,
    uid: Ce()
  };
  function w(h) {
    return (_) => {
      const b = _(h);
      for (const P in m)
        delete b[P];
      const C = { ...h, ...b };
      return Object.assign(C, { extend: w(C) });
    };
  }
  return Object.assign(m, { extend: w(m) });
}
function $e({ key: t, methods: n, name: r, request: a, retryCount: s = 3, retryDelay: u = 150, timeout: p, type: f }, o) {
  const e = Ce();
  return {
    config: {
      key: t,
      methods: n,
      name: r,
      request: a,
      retryCount: s,
      retryDelay: u,
      timeout: p,
      type: f
    },
    request: ke(a, { methods: n, retryCount: s, retryDelay: u, uid: e }),
    value: o
  };
}
class Ne extends ye {
  constructor() {
    super("No URL was provided to the Transport. Please provide a valid RPC URL to the Transport.", {
      docsPath: "/docs/clients/intro",
      name: "UrlRequiredError"
    });
  }
}
function ct(t, n = {}) {
  const { batch: r, fetchOptions: a, key: s = "http", methods: u, name: p = "HTTP JSON-RPC", onFetchRequest: f, onFetchResponse: o, retryDelay: e, raw: i } = n;
  return ({ chain: c, retryCount: d, timeout: v }) => {
    const { batchSize: m = 1e3, wait: w = 0 } = typeof r == "object" ? r : {}, h = n.retryCount ?? d, _ = v ?? n.timeout ?? 1e4, b = t || c?.rpcUrls.default.http[0];
    if (!b)
      throw new Ne();
    const C = Le(b, {
      fetchOptions: a,
      onRequest: f,
      onResponse: o,
      timeout: _
    });
    return $e({
      key: s,
      methods: u,
      name: p,
      async request({ method: P, params: l }) {
        const g = { method: P, params: l }, { schedule: y } = xe({
          id: b,
          wait: w,
          shouldSplitBatch(E) {
            return E.length > m;
          },
          fn: (E) => C.request({
            body: E
          }),
          sort: (E, T) => E.id - T.id
        }), S = async (E) => r ? y(E) : [
          await C.request({
            body: E
          })
        ], [{ error: I, result: R }] = await S(g);
        if (i)
          return { error: I, result: R };
        if (I)
          throw new Oe({
            body: g,
            error: I,
            url: b
          });
        return R;
      },
      retryCount: h,
      retryDelay: e,
      timeout: _,
      type: "http"
    }, {
      fetchOptions: a,
      url: b
    });
  };
}
function at(t) {
  return t;
}
Q.type = "injected";
function Q(t = {}) {
  const { shimDisconnect: n = !0, unstable_shimAsyncInject: r } = t;
  function a() {
    const o = t.target;
    if (typeof o == "function") {
      const e = o();
      if (e)
        return e;
    }
    return typeof o == "object" ? o : typeof o == "string" ? {
      ...We[o] ?? {
        id: o,
        name: `${o[0].toUpperCase()}${o.slice(1)}`,
        provider: `is${o[0].toUpperCase()}${o.slice(1)}`
      }
    } : {
      id: "injected",
      name: "Injected",
      provider(e) {
        return e?.ethereum;
      }
    };
  }
  let s, u, p, f;
  return (o) => ({
    get icon() {
      return a().icon;
    },
    get id() {
      return a().id;
    },
    get name() {
      return a().name;
    },
    /** @deprecated */
    get supportsSimulation() {
      return !0;
    },
    type: Q.type,
    async setup() {
      const e = await this.getProvider();
      e?.on && t.target && (p || (p = this.onConnect.bind(this), e.on("connect", p)), s || (s = this.onAccountsChanged.bind(this), e.on("accountsChanged", s)));
    },
    async connect({ chainId: e, isReconnecting: i } = {}) {
      const c = await this.getProvider();
      if (!c)
        throw new q();
      let d = [];
      if (i)
        d = await this.getAccounts().catch(() => []);
      else if (n)
        try {
          d = (await c.request({
            method: "wallet_requestPermissions",
            params: [{ eth_accounts: {} }]
          }))[0]?.caveats?.[0]?.value?.map((m) => L(m)), d.length > 0 && (d = await this.getAccounts());
        } catch (v) {
          const m = v;
          if (m.code === j.code)
            throw new j(m);
          if (m.code === k.code)
            throw m;
        }
      try {
        !d?.length && !i && (d = (await c.request({
          method: "eth_requestAccounts"
        })).map((w) => L(w))), p && (c.removeListener("connect", p), p = void 0), s || (s = this.onAccountsChanged.bind(this), c.on("accountsChanged", s)), u || (u = this.onChainChanged.bind(this), c.on("chainChanged", u)), f || (f = this.onDisconnect.bind(this), c.on("disconnect", f));
        let v = await this.getChainId();
        return e && v !== e && (v = (await this.switchChain({ chainId: e }).catch((w) => {
          if (w.code === j.code)
            throw w;
          return { id: v };
        }))?.id ?? v), n && await o.storage?.removeItem(`${this.id}.disconnected`), t.target || await o.storage?.setItem("injected.connected", !0), { accounts: d, chainId: v };
      } catch (v) {
        const m = v;
        throw m.code === j.code ? new j(m) : m.code === k.code ? new k(m) : m;
      }
    },
    async disconnect() {
      const e = await this.getProvider();
      if (!e)
        throw new q();
      u && (e.removeListener("chainChanged", u), u = void 0), f && (e.removeListener("disconnect", f), f = void 0), p || (p = this.onConnect.bind(this), e.on("connect", p));
      try {
        await be(() => (
          // TODO: Remove explicit type for viem@3
          e.request({
            // `'wallet_revokePermissions'` added in `viem@2.10.3`
            method: "wallet_revokePermissions",
            params: [{ eth_accounts: {} }]
          })
        ), { timeout: 100 });
      } catch {
      }
      n && await o.storage?.setItem(`${this.id}.disconnected`, !0), t.target || await o.storage?.removeItem("injected.connected");
    },
    async getAccounts() {
      const e = await this.getProvider();
      if (!e)
        throw new q();
      return (await e.request({ method: "eth_accounts" })).map((c) => L(c));
    },
    async getChainId() {
      const e = await this.getProvider();
      if (!e)
        throw new q();
      const i = await e.request({ method: "eth_chainId" });
      return Number(i);
    },
    async getProvider() {
      if (typeof window > "u")
        return;
      let e;
      const i = a();
      return typeof i.provider == "function" ? e = i.provider(window) : typeof i.provider == "string" ? e = z(window, i.provider) : e = i.provider, e && !e.removeListener && ("off" in e && typeof e.off == "function" ? e.removeListener = e.off : e.removeListener = () => {
      }), e;
    },
    async isAuthorized() {
      try {
        if (n && // If shim exists in storage, connector is disconnected
        await o.storage?.getItem(`${this.id}.disconnected`) || !t.target && !await o.storage?.getItem("injected.connected"))
          return !1;
        if (!await this.getProvider()) {
          if (r !== void 0 && r !== !1) {
            const d = async () => (typeof window < "u" && window.removeEventListener("ethereum#initialized", d), !!await this.getProvider()), v = typeof r == "number" ? r : 1e3;
            if (await Promise.race([
              ...typeof window < "u" ? [
                new Promise((w) => window.addEventListener("ethereum#initialized", () => w(d()), { once: !0 }))
              ] : [],
              new Promise((w) => setTimeout(() => w(d()), v))
            ]))
              return !0;
          }
          throw new q();
        }
        return !!(await ve(() => this.getAccounts())).length;
      } catch {
        return !1;
      }
    },
    async switchChain({ addEthereumChainParameter: e, chainId: i }) {
      const c = await this.getProvider();
      if (!c)
        throw new q();
      const d = o.chains.find((m) => m.id === i);
      if (!d)
        throw new F(new Z());
      const v = new Promise((m) => {
        const w = (h) => {
          "chainId" in h && h.chainId === i && (o.emitter.off("change", w), m());
        };
        o.emitter.on("change", w);
      });
      try {
        return await Promise.all([
          c.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: he(i) }]
          }).then(async () => {
            await this.getChainId() === i && o.emitter.emit("change", { chainId: i });
          }),
          v
        ]), d;
      } catch (m) {
        const w = m;
        if (w.code === 4902 || // Unwrapping for MetaMask Mobile
        // https://github.com/MetaMask/metamask-mobile/issues/2944#issuecomment-976988719
        w?.data?.originalError?.code === 4902)
          try {
            const { default: h, ..._ } = d.blockExplorers ?? {};
            let b;
            e?.blockExplorerUrls ? b = e.blockExplorerUrls : h && (b = [
              h.url,
              ...Object.values(_).map((l) => l.url)
            ]);
            let C;
            e?.rpcUrls?.length ? C = e.rpcUrls : C = [d.rpcUrls.default?.http[0] ?? ""];
            const P = {
              blockExplorerUrls: b,
              chainId: he(i),
              chainName: e?.chainName ?? d.name,
              iconUrls: e?.iconUrls,
              nativeCurrency: e?.nativeCurrency ?? d.nativeCurrency,
              rpcUrls: C
            };
            return await Promise.all([
              c.request({
                method: "wallet_addEthereumChain",
                params: [P]
              }).then(async () => {
                if (await this.getChainId() === i)
                  o.emitter.emit("change", { chainId: i });
                else
                  throw new j(new Error("User rejected switch after adding network."));
              }),
              v
            ]), d;
          } catch (h) {
            throw new j(h);
          }
        throw w.code === j.code ? new j(w) : new F(w);
      }
    },
    async onAccountsChanged(e) {
      if (e.length === 0)
        this.onDisconnect();
      else if (o.emitter.listenerCount("connect")) {
        const i = (await this.getChainId()).toString();
        this.onConnect({ chainId: i }), n && await o.storage?.removeItem(`${this.id}.disconnected`);
      } else
        o.emitter.emit("change", {
          accounts: e.map((i) => L(i))
        });
    },
    onChainChanged(e) {
      const i = Number(e);
      o.emitter.emit("change", { chainId: i });
    },
    async onConnect(e) {
      const i = await this.getAccounts();
      if (i.length === 0)
        return;
      const c = Number(e.chainId);
      o.emitter.emit("connect", { accounts: i, chainId: c });
      const d = await this.getProvider();
      d && (p && (d.removeListener("connect", p), p = void 0), s || (s = this.onAccountsChanged.bind(this), d.on("accountsChanged", s)), u || (u = this.onChainChanged.bind(this), d.on("chainChanged", u)), f || (f = this.onDisconnect.bind(this), d.on("disconnect", f)));
    },
    async onDisconnect(e) {
      const i = await this.getProvider();
      e && e.code === 1013 && i && (await this.getAccounts()).length || (o.emitter.emit("disconnect"), i && (u && (i.removeListener("chainChanged", u), u = void 0), f && (i.removeListener("disconnect", f), f = void 0), p || (p = this.onConnect.bind(this), i.on("connect", p))));
    }
  });
}
const We = {
  coinbaseWallet: {
    id: "coinbaseWallet",
    name: "Coinbase Wallet",
    provider(t) {
      return t?.coinbaseWalletExtension ? t.coinbaseWalletExtension : z(t, "isCoinbaseWallet");
    }
  },
  metaMask: {
    id: "metaMask",
    name: "MetaMask",
    provider(t) {
      return z(t, (n) => {
        if (!n.isMetaMask || n.isBraveWallet && !n._events && !n._state)
          return !1;
        const r = [
          "isApexWallet",
          "isAvalanche",
          "isBitKeep",
          "isBlockWallet",
          "isKuCoinWallet",
          "isMathWallet",
          "isOkxWallet",
          "isOKExWallet",
          "isOneInchIOSWallet",
          "isOneInchAndroidWallet",
          "isOpera",
          "isPhantom",
          "isPortal",
          "isRabby",
          "isTokenPocket",
          "isTokenary",
          "isUniswapWallet",
          "isZerion"
        ];
        for (const a of r)
          if (n[a])
            return !1;
        return !0;
      });
    }
  },
  phantom: {
    id: "phantom",
    name: "Phantom",
    provider(t) {
      return t?.phantom?.ethereum ? t.phantom?.ethereum : z(t, "isPhantom");
    }
  }
};
function z(t, n) {
  function r(s) {
    return typeof n == "function" ? n(s) : typeof n == "string" ? s[n] : !0;
  }
  const a = t.ethereum;
  if (a?.providers)
    return a.providers.find((s) => r(s));
  if (a && r(a))
    return a;
}
function De(t) {
  if (typeof window > "u")
    return;
  const n = (r) => t(r.detail);
  return window.addEventListener("eip6963:announceProvider", n), window.dispatchEvent(new CustomEvent("eip6963:requestProvider")), () => window.removeEventListener("eip6963:announceProvider", n);
}
function He() {
  const t = /* @__PURE__ */ new Set();
  let n = [];
  const r = () => De((s) => {
    n.some(({ info: u }) => u.uuid === s.info.uuid) || (n = [...n, s], t.forEach((u) => u(n, { added: [s] })));
  });
  let a = r();
  return {
    _listeners() {
      return t;
    },
    clear() {
      t.forEach((s) => s([], { removed: [...n] })), n = [];
    },
    destroy() {
      this.clear(), t.clear(), a?.();
    },
    findProvider({ rdns: s }) {
      return n.find((u) => u.info.rdns === s);
    },
    getProviders() {
      return n;
    },
    reset() {
      this.clear(), a?.(), a = r();
    },
    subscribe(s, { emitImmediately: u } = {}) {
      return t.add(s), u && s(n, { added: n }), () => t.delete(s);
    }
  };
}
const ze = (t) => (n, r, a) => {
  const s = a.subscribe;
  return a.subscribe = (p, f, o) => {
    let e = p;
    if (f) {
      const i = o?.equalityFn || Object.is;
      let c = p(a.getState());
      e = (d) => {
        const v = p(d);
        if (!i(c, v)) {
          const m = c;
          f(c = v, m);
        }
      }, o?.fireImmediately && f(c, c);
    }
    return s(e);
  }, t(n, r, a);
}, Fe = ze;
function Be(t, n) {
  let r;
  try {
    r = t();
  } catch {
    return;
  }
  return {
    getItem: (s) => {
      var u;
      const p = (o) => o === null ? null : JSON.parse(o, void 0), f = (u = r.getItem(s)) != null ? u : null;
      return f instanceof Promise ? f.then(p) : p(f);
    },
    setItem: (s, u) => r.setItem(
      s,
      JSON.stringify(u, void 0)
    ),
    removeItem: (s) => r.removeItem(s)
  };
}
const X = (t) => (n) => {
  try {
    const r = t(n);
    return r instanceof Promise ? r : {
      then(a) {
        return X(a)(r);
      },
      catch(a) {
        return this;
      }
    };
  } catch (r) {
    return {
      then(a) {
        return this;
      },
      catch(a) {
        return X(a)(r);
      }
    };
  }
}, Je = (t, n) => (r, a, s) => {
  let u = {
    storage: Be(() => localStorage),
    partialize: (w) => w,
    version: 0,
    merge: (w, h) => ({
      ...h,
      ...w
    }),
    ...n
  }, p = !1;
  const f = /* @__PURE__ */ new Set(), o = /* @__PURE__ */ new Set();
  let e = u.storage;
  if (!e)
    return t(
      (...w) => {
        console.warn(
          `[zustand persist middleware] Unable to update item '${u.name}', the given storage is currently unavailable.`
        ), r(...w);
      },
      a,
      s
    );
  const i = () => {
    const w = u.partialize({ ...a() });
    return e.setItem(u.name, {
      state: w,
      version: u.version
    });
  }, c = s.setState;
  s.setState = (w, h) => {
    c(w, h), i();
  };
  const d = t(
    (...w) => {
      r(...w), i();
    },
    a,
    s
  );
  s.getInitialState = () => d;
  let v;
  const m = () => {
    var w, h;
    if (!e) return;
    p = !1, f.forEach((b) => {
      var C;
      return b((C = a()) != null ? C : d);
    });
    const _ = ((h = u.onRehydrateStorage) == null ? void 0 : h.call(u, (w = a()) != null ? w : d)) || void 0;
    return X(e.getItem.bind(e))(u.name).then((b) => {
      if (b)
        if (typeof b.version == "number" && b.version !== u.version) {
          if (u.migrate)
            return [
              !0,
              u.migrate(
                b.state,
                b.version
              )
            ];
          console.error(
            "State loaded from storage couldn't be migrated since no migrate function was provided"
          );
        } else
          return [!1, b.state];
      return [!1, void 0];
    }).then((b) => {
      var C;
      const [P, l] = b;
      if (v = u.merge(
        l,
        (C = a()) != null ? C : d
      ), r(v, !0), P)
        return i();
    }).then(() => {
      _?.(v, void 0), v = a(), p = !0, o.forEach((b) => b(v));
    }).catch((b) => {
      _?.(void 0, b);
    });
  };
  return s.persist = {
    setOptions: (w) => {
      u = {
        ...u,
        ...w
      }, w.storage && (e = w.storage);
    },
    clearStorage: () => {
      e?.removeItem(u.name);
    },
    getOptions: () => u,
    rehydrate: () => m(),
    hasHydrated: () => p,
    onHydrate: (w) => (f.add(w), () => {
      f.delete(w);
    }),
    onFinishHydration: (w) => (o.add(w), () => {
      o.delete(w);
    })
  }, u.skipHydration || m(), v || d;
}, Ke = Je, me = (t) => {
  let n;
  const r = /* @__PURE__ */ new Set(), a = (e, i) => {
    const c = typeof e == "function" ? e(n) : e;
    if (!Object.is(c, n)) {
      const d = n;
      n = i ?? (typeof c != "object" || c === null) ? c : Object.assign({}, n, c), r.forEach((v) => v(n, d));
    }
  }, s = () => n, f = { setState: a, getState: s, getInitialState: () => o, subscribe: (e) => (r.add(e), () => r.delete(e)) }, o = n = t(a, s, f);
  return f;
}, J = (t) => t ? me(t) : me;
var ut = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Ve(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
function dt(t) {
  if (t.__esModule) return t;
  var n = t.default;
  if (typeof n == "function") {
    var r = function a() {
      return this instanceof a ? Reflect.construct(n, arguments, this.constructor) : n.apply(this, arguments);
    };
    r.prototype = n.prototype;
  } else r = {};
  return Object.defineProperty(r, "__esModule", { value: !0 }), Object.keys(t).forEach(function(a) {
    var s = Object.getOwnPropertyDescriptor(t, a);
    Object.defineProperty(r, a, s.get ? s : {
      enumerable: !0,
      get: function() {
        return t[a];
      }
    });
  }), r;
}
var Ie = { exports: {} };
(function(t) {
  var n = Object.prototype.hasOwnProperty, r = "~";
  function a() {
  }
  Object.create && (a.prototype = /* @__PURE__ */ Object.create(null), new a().__proto__ || (r = !1));
  function s(o, e, i) {
    this.fn = o, this.context = e, this.once = i || !1;
  }
  function u(o, e, i, c, d) {
    if (typeof i != "function")
      throw new TypeError("The listener must be a function");
    var v = new s(i, c || o, d), m = r ? r + e : e;
    return o._events[m] ? o._events[m].fn ? o._events[m] = [o._events[m], v] : o._events[m].push(v) : (o._events[m] = v, o._eventsCount++), o;
  }
  function p(o, e) {
    --o._eventsCount === 0 ? o._events = new a() : delete o._events[e];
  }
  function f() {
    this._events = new a(), this._eventsCount = 0;
  }
  f.prototype.eventNames = function() {
    var e = [], i, c;
    if (this._eventsCount === 0) return e;
    for (c in i = this._events)
      n.call(i, c) && e.push(r ? c.slice(1) : c);
    return Object.getOwnPropertySymbols ? e.concat(Object.getOwnPropertySymbols(i)) : e;
  }, f.prototype.listeners = function(e) {
    var i = r ? r + e : e, c = this._events[i];
    if (!c) return [];
    if (c.fn) return [c.fn];
    for (var d = 0, v = c.length, m = new Array(v); d < v; d++)
      m[d] = c[d].fn;
    return m;
  }, f.prototype.listenerCount = function(e) {
    var i = r ? r + e : e, c = this._events[i];
    return c ? c.fn ? 1 : c.length : 0;
  }, f.prototype.emit = function(e, i, c, d, v, m) {
    var w = r ? r + e : e;
    if (!this._events[w]) return !1;
    var h = this._events[w], _ = arguments.length, b, C;
    if (h.fn) {
      switch (h.once && this.removeListener(e, h.fn, void 0, !0), _) {
        case 1:
          return h.fn.call(h.context), !0;
        case 2:
          return h.fn.call(h.context, i), !0;
        case 3:
          return h.fn.call(h.context, i, c), !0;
        case 4:
          return h.fn.call(h.context, i, c, d), !0;
        case 5:
          return h.fn.call(h.context, i, c, d, v), !0;
        case 6:
          return h.fn.call(h.context, i, c, d, v, m), !0;
      }
      for (C = 1, b = new Array(_ - 1); C < _; C++)
        b[C - 1] = arguments[C];
      h.fn.apply(h.context, b);
    } else {
      var P = h.length, l;
      for (C = 0; C < P; C++)
        switch (h[C].once && this.removeListener(e, h[C].fn, void 0, !0), _) {
          case 1:
            h[C].fn.call(h[C].context);
            break;
          case 2:
            h[C].fn.call(h[C].context, i);
            break;
          case 3:
            h[C].fn.call(h[C].context, i, c);
            break;
          case 4:
            h[C].fn.call(h[C].context, i, c, d);
            break;
          default:
            if (!b) for (l = 1, b = new Array(_ - 1); l < _; l++)
              b[l - 1] = arguments[l];
            h[C].fn.apply(h[C].context, b);
        }
    }
    return !0;
  }, f.prototype.on = function(e, i, c) {
    return u(this, e, i, c, !1);
  }, f.prototype.once = function(e, i, c) {
    return u(this, e, i, c, !0);
  }, f.prototype.removeListener = function(e, i, c, d) {
    var v = r ? r + e : e;
    if (!this._events[v]) return this;
    if (!i)
      return p(this, v), this;
    var m = this._events[v];
    if (m.fn)
      m.fn === i && (!d || m.once) && (!c || m.context === c) && p(this, v);
    else {
      for (var w = 0, h = [], _ = m.length; w < _; w++)
        (m[w].fn !== i || d && !m[w].once || c && m[w].context !== c) && h.push(m[w]);
      h.length ? this._events[v] = h.length === 1 ? h[0] : h : p(this, v);
    }
    return this;
  }, f.prototype.removeAllListeners = function(e) {
    var i;
    return e ? (i = r ? r + e : e, this._events[i] && p(this, i)) : (this._events = new a(), this._eventsCount = 0), this;
  }, f.prototype.off = f.prototype.removeListener, f.prototype.addListener = f.prototype.on, f.prefixed = r, f.EventEmitter = f, t.exports = f;
})(Ie);
var Ze = Ie.exports;
const Ge = /* @__PURE__ */ Ve(Ze);
class Xe {
  constructor(n) {
    Object.defineProperty(this, "uid", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: n
    }), Object.defineProperty(this, "_emitter", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: new Ge()
    });
  }
  on(n, r) {
    this._emitter.on(n, r);
  }
  once(n, r) {
    this._emitter.once(n, r);
  }
  off(n, r) {
    this._emitter.off(n, r);
  }
  emit(n, ...r) {
    const a = r[0];
    this._emitter.emit(n, { uid: this.uid, ...a });
  }
  listenerCount(n) {
    return this._emitter.listenerCount(n);
  }
}
function Ye(t) {
  return new Xe(t);
}
function Qe(t, n) {
  return JSON.parse(t, (r, a) => {
    let s = a;
    return s?.__type === "bigint" && (s = BigInt(s.value)), s?.__type === "Map" && (s = new Map(s.value)), n?.(r, s) ?? s;
  });
}
function we(t, n) {
  return t.slice(0, n).join(".") || ".";
}
function ge(t, n) {
  const { length: r } = t;
  for (let a = 0; a < r; ++a)
    if (t[a] === n)
      return a + 1;
  return 0;
}
function et(t, n) {
  const r = typeof t == "function", a = typeof n == "function", s = [], u = [];
  return function(f, o) {
    if (typeof o == "object")
      if (s.length) {
        const e = ge(s, this);
        e === 0 ? s[s.length] = this : (s.splice(e), u.splice(e)), u[u.length] = f;
        const i = ge(s, o);
        if (i !== 0)
          return a ? n.call(this, f, o, we(u, i)) : `[ref=${we(u, i)}]`;
      } else
        s[0] = o, u[0] = f;
    return r ? t.call(this, f, o) : o;
  };
}
function tt(t, n, r, a) {
  return JSON.stringify(t, et((s, u) => {
    let p = u;
    return typeof p == "bigint" && (p = { __type: "bigint", value: u.toString() }), p instanceof Map && (p = { __type: "Map", value: Array.from(u.entries()) }), n?.(s, p) ?? p;
  }, a), r ?? void 0);
}
function nt(t) {
  const { deserialize: n = Qe, key: r = "wagmi", serialize: a = tt, storage: s = Se } = t;
  function u(p) {
    return p instanceof Promise ? p.then((f) => f).catch(() => null) : p;
  }
  return {
    ...s,
    key: r,
    async getItem(p, f) {
      const o = s.getItem(`${r}.${p}`), e = await u(o);
      return e ? n(e) ?? null : f ?? null;
    },
    async setItem(p, f) {
      const o = `${r}.${p}`;
      f === null ? await u(s.removeItem(o)) : await u(s.setItem(o, a(f)));
    },
    async removeItem(p) {
      await u(s.removeItem(`${r}.${p}`));
    }
  };
}
const Se = {
  getItem: () => null,
  setItem: () => {
  },
  removeItem: () => {
  }
};
function rt() {
  const t = typeof window < "u" && window.localStorage ? window.localStorage : Se;
  return {
    getItem(n) {
      return t.getItem(n);
    },
    removeItem(n) {
      t.removeItem(n);
    },
    setItem(n, r) {
      try {
        t.setItem(n, r);
      } catch {
      }
    }
  };
}
const Y = 256;
let W = Y, D;
function ot(t = 11) {
  if (!D || W + t > Y * 2) {
    D = "", W = 0;
    for (let n = 0; n < Y; n++)
      D += (256 + Math.random() * 256 | 0).toString(16).substring(1);
  }
  return D.substring(W, W++ + t);
}
function ft(t) {
  const { multiInjectedProviderDiscovery: n = !0, storage: r = nt({
    storage: rt()
  }), syncConnectedChain: a = !0, ssr: s = !1, ...u } = t, p = typeof window < "u" && n ? He() : void 0, f = J(() => u.chains), o = J(() => {
    const l = [], g = /* @__PURE__ */ new Set();
    for (const y of u.connectors ?? []) {
      const S = e(y);
      if (l.push(S), !s && S.rdns) {
        const I = typeof S.rdns == "string" ? [S.rdns] : S.rdns;
        for (const R of I)
          g.add(R);
      }
    }
    if (!s && p) {
      const y = p.getProviders();
      for (const S of y)
        g.has(S.info.rdns) || l.push(e(i(S)));
    }
    return l;
  });
  function e(l) {
    const g = Ye(ot()), y = {
      ...l({
        emitter: g,
        chains: f.getState(),
        storage: r,
        transports: u.transports
      }),
      emitter: g,
      uid: g.uid
    };
    return g.on("connect", C), y.setup?.(), y;
  }
  function i(l) {
    const { info: g } = l, y = l.provider;
    return Q({ target: { ...g, id: g.rdns, provider: y } });
  }
  const c = /* @__PURE__ */ new Map();
  function d(l = {}) {
    const g = l.chainId ?? h.getState().chainId, y = f.getState().find((I) => I.id === g);
    if (l.chainId && !y)
      throw new Z();
    {
      const I = c.get(h.getState().chainId);
      if (I && !y)
        return I;
      if (!y)
        throw new Z();
    }
    {
      const I = c.get(g);
      if (I)
        return I;
    }
    let S;
    if (u.client)
      S = u.client({ chain: y });
    else {
      const I = y.id, R = f.getState().map((O) => O.id), E = {}, T = Object.entries(u);
      for (const [O, x] of T)
        if (!(O === "chains" || O === "client" || O === "connectors" || O === "transports"))
          if (typeof x == "object")
            if (I in x)
              E[O] = x[I];
            else {
              if (R.some((_e) => _e in x))
                continue;
              E[O] = x;
            }
          else
            E[O] = x;
      S = Ue({
        ...E,
        chain: y,
        batch: E.batch ?? { multicall: !0 },
        transport: (O) => u.transports[I]({ ...O, connectors: o })
      });
    }
    return c.set(g, S), S;
  }
  function v() {
    return {
      chainId: f.getState()[0].id,
      connections: /* @__PURE__ */ new Map(),
      current: null,
      status: "disconnected"
    };
  }
  let m;
  const w = "0.0.0-canary-";
  B.startsWith(w) ? m = Number.parseInt(B.replace(w, "")) : m = Number.parseInt(B.split(".")[0] ?? "0");
  const h = J(Fe(
    // only use persist middleware if storage exists
    r ? Ke(v, {
      migrate(l, g) {
        if (g === m)
          return l;
        const y = v(), S = _(l, y.chainId);
        return { ...y, chainId: S };
      },
      name: "store",
      partialize(l) {
        return {
          connections: {
            __type: "Map",
            value: Array.from(l.connections.entries()).map(([g, y]) => {
              const { id: S, name: I, type: R, uid: E } = y.connector;
              return [g, { ...y, connector: { id: S, name: I, type: R, uid: E } }];
            })
          },
          chainId: l.chainId,
          current: l.current
        };
      },
      merge(l, g) {
        typeof l == "object" && l && "status" in l && delete l.status;
        const y = _(l, g.chainId);
        return {
          ...g,
          ...l,
          chainId: y
        };
      },
      skipHydration: s,
      storage: r,
      version: m
    }) : v
  ));
  h.setState(v());
  function _(l, g) {
    return l && typeof l == "object" && "chainId" in l && typeof l.chainId == "number" && f.getState().some((y) => y.id === l.chainId) ? l.chainId : g;
  }
  a && h.subscribe(({ connections: l, current: g }) => g ? l.get(g)?.chainId : void 0, (l) => {
    if (f.getState().some((y) => y.id === l))
      return h.setState((y) => ({
        ...y,
        chainId: l ?? y.chainId
      }));
  }), p?.subscribe((l) => {
    const g = /* @__PURE__ */ new Set(), y = /* @__PURE__ */ new Set();
    for (const I of o.getState())
      if (g.add(I.id), I.rdns) {
        const R = typeof I.rdns == "string" ? [I.rdns] : I.rdns;
        for (const E of R)
          y.add(E);
      }
    const S = [];
    for (const I of l) {
      if (y.has(I.info.rdns))
        continue;
      const R = e(i(I));
      g.has(R.id) || S.push(R);
    }
    r && !h.persist.hasHydrated() || o.setState((I) => [...I, ...S], !0);
  });
  function b(l) {
    h.setState((g) => {
      const y = g.connections.get(l.uid);
      return y ? {
        ...g,
        connections: new Map(g.connections).set(l.uid, {
          accounts: l.accounts ?? y.accounts,
          chainId: l.chainId ?? y.chainId,
          connector: y.connector
        })
      } : g;
    });
  }
  function C(l) {
    h.getState().status === "connecting" || h.getState().status === "reconnecting" || h.setState((g) => {
      const y = o.getState().find((S) => S.uid === l.uid);
      return y ? (y.emitter.listenerCount("connect") && y.emitter.off("connect", b), y.emitter.listenerCount("change") || y.emitter.on("change", b), y.emitter.listenerCount("disconnect") || y.emitter.on("disconnect", P), {
        ...g,
        connections: new Map(g.connections).set(l.uid, {
          accounts: l.accounts,
          chainId: l.chainId,
          connector: y
        }),
        current: l.uid,
        status: "connected"
      }) : g;
    });
  }
  function P(l) {
    h.setState((g) => {
      const y = g.connections.get(l.uid);
      if (y) {
        const I = y.connector;
        I.emitter.listenerCount("change") && y.connector.emitter.off("change", b), I.emitter.listenerCount("disconnect") && y.connector.emitter.off("disconnect", P), I.emitter.listenerCount("connect") || y.connector.emitter.on("connect", C);
      }
      if (g.connections.delete(l.uid), g.connections.size === 0)
        return {
          ...g,
          connections: /* @__PURE__ */ new Map(),
          current: null,
          status: "disconnected"
        };
      const S = g.connections.values().next().value;
      return {
        ...g,
        connections: new Map(g.connections),
        current: S.connector.uid
      };
    });
  }
  return {
    get chains() {
      return f.getState();
    },
    get connectors() {
      return o.getState();
    },
    storage: r,
    getClient: d,
    get state() {
      return h.getState();
    },
    setState(l) {
      let g;
      typeof l == "function" ? g = l(h.getState()) : g = l;
      const y = v();
      typeof g != "object" && (g = y), Object.keys(y).some((I) => !(I in g)) && (g = y), h.setState(g, !0);
    },
    subscribe(l, g, y) {
      return h.subscribe(l, g, y ? {
        ...y,
        fireImmediately: y.emitImmediately
        // Workaround cast since Zustand does not support `'exactOptionalPropertyTypes'`
      } : void 0);
    },
    _internal: {
      mipd: p,
      store: h,
      ssr: !!s,
      syncConnectedChain: a,
      transports: u.transports,
      chains: {
        setState(l) {
          const g = typeof l == "function" ? l(f.getState()) : l;
          if (g.length !== 0)
            return f.setState(g, !0);
        },
        subscribe(l) {
          return f.subscribe(l);
        }
      },
      connectors: {
        providerDetailToConnector: i,
        setup: e,
        setState(l) {
          return o.setState(typeof l == "function" ? l(o.getState()) : l, !0);
        },
        subscribe(l) {
          return o.subscribe(l);
        }
      },
      events: { change: b, connect: C, disconnect: P }
    }
  };
}
export {
  Ge as O,
  Ne as U,
  ft as a,
  pe as b,
  at as c,
  be as d,
  Le as e,
  Ae as f,
  Ve as g,
  ct as h,
  Q as i,
  $e as j,
  Ue as k,
  Qe as l,
  nt as m,
  Se as n,
  ut as o,
  dt as p,
  Ze as q,
  tt as s,
  ve as w
};
