import { V as l } from "./VIcon-tQI7dOze.js";
import { resolveComponent as d, openBlock as n, createBlock as i, resolveDynamicComponent as r, mergeProps as c, withCtx as s, createElementVNode as u, createCommentVNode as f, renderSlot as g, createTextVNode as h, toDisplayString as m } from "vue";
import { _ as b } from "./_plugin-vue_export-helper-hUChTQA_.js";
const y = {
  components: {
    VIcon: l
  },
  props: {
    tag: {
      type: String,
      default: "button"
    },
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "button"
    },
    to: {
      type: [String, Object],
      default: void 0
    },
    href: {
      type: String,
      default: void 0
    },
    target: {
      type: String,
      default: void 0
    },
    variant: {
      type: String,
      default: "primary"
    },
    size: {
      type: String,
      default: ""
    },
    iconName: {
      type: String,
      default: ""
    },
    iconWidth: {
      type: String,
      default: ""
    },
    iconHeight: {
      type: String,
      default: ""
    },
    iconSize: {
      type: String,
      default: ""
    },
    disabled: Boolean,
    isFullWidth: Boolean,
    isLoading: Boolean
  },
  computed: {
    buttonProperties() {
      switch (this.tag) {
        case "button":
          return {
            type: this.type
          };
        case "a":
          return {
            href: this.href,
            target: this.target
          };
        case "router-link":
          return {
            to: this.to
          };
        default:
          return {};
      }
    }
  }
}, S = { class: "btn__content" };
function _(e, V, t, z, B, a) {
  const o = d("VIcon");
  return n(), i(r(t.tag), c(a.buttonProperties, {
    disabled: t.disabled,
    class: ["btn", {
      "w-100": t.isFullWidth,
      "btn--icon-only": !t.label && !e.$slots.default,
      "btn--loading": t.isLoading,
      disabled: t.disabled,
      [`btn-${t.variant}`]: t.variant,
      [`btn-${t.size}`]: t.size
    }]
  }), {
    default: s(() => [
      u("span", S, [
        t.iconName ? (n(), i(o, {
          key: 0,
          name: t.iconName,
          width: t.iconWidth,
          height: t.iconHeight,
          size: t.iconSize,
          class: "btn__icon"
        }, null, 8, ["name", "width", "height", "size"])) : f("", !0),
        g(e.$slots, "default", {}, () => [
          h(m(t.label), 1)
        ])
      ])
    ]),
    _: 3
  }, 16, ["disabled", "class"]);
}
const w = /* @__PURE__ */ b(y, [["render", _]]);
export {
  w as V
};
