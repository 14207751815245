import { defineStore } from 'pinia';
import {
  getUser,
  saveProfile,
  resetIdv,
} from '@swapin-hub/api/user';

import {
  PROFILE_TYPE,
  PROFILE_STATUS,
} from '@/const';

const DEFAULT_STATE = {
  profile: null,
  verificationProfile: null,
};

export const useProfileStore = defineStore('profile', {
  state: () => ({ ...DEFAULT_STATE }),

  getters: {
    hasProfile: ({ profile }) => !!profile,

    isProfileIncomplete: ({ profile }) => profile?.status === PROFILE_STATUS.INCOMPLETE,

    isProfilePending: ({ profile }) => profile?.status === PROFILE_STATUS.PENDING,

    isProfileComplete: ({ profile }) => profile?.status === PROFILE_STATUS.COMPLETE && !profile.is_verify_required,

    isProfileNoService: ({ profile }) => profile?.status === PROFILE_STATUS.NO_SERVICE,

    isRequiredToVerify: ({ profile }) => profile?.status === PROFILE_STATUS.COMPLETE && profile.is_verify_required,

    has3rdPartyPaymentsCapability: ({ profile }) => !!profile?.has_3rd_party_payments_capability,

    isPersonalProfile: ({ profile }) => profile?.type === PROFILE_TYPE.PERSONAL,

    isCompanyProfile: ({ profile }) => profile?.type === PROFILE_TYPE.COMPANY,

    isVerifiedProfile: ({ profile, isRequiredToVerify }) => {
      if (!profile || isRequiredToVerify) {
        return false;
      }

      const { status, checked_level } = profile;
      const isCheckedLevelVerified = checked_level && [
        PROFILE_STATUS.PENDING,
        PROFILE_STATUS.PENDING_UP,
      ].includes(status);

      return status === PROFILE_STATUS.COMPLETE || isCheckedLevelVerified;
    },

    profileFullName: ({ profile }) => {
      if (!profile) {
        return '';
      }

      const { first_name, last_name } = profile;

      return (`${first_name || ''} ${last_name || ''}`).trim();
    },
  },

  actions: {
    setProfile(profile) {
      this.profile = profile;
    },

    async getProfile() {
      const profile = await getUser();

      this.profile = profile;

      return profile;
    },

    async updateProfile(profile) {
      const data = await saveProfile(profile);

      this.profile = data.user;

      return data;
    },

    async resubmitID() {
      const data = await resetIdv();

      this.profile = data.user;

      return data;
    },

    reset() {
      Object.assign(this, DEFAULT_STATE);
    },
  },
});
